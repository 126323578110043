<template>
	<v-row no-gutters dense class="list-home delivery-home">
		<v-col cols="12" class="pa-0">
			<v-row no-gutters class="align-center">
				<v-col cols="4" sm="2">
					<v-btn @click="back" class="my-back-btn mt-1" depressed>
						<img :src="$store.state.icons.icons['LeftArrow']" class="back-btn" alt />
						Back
					</v-btn>
				</v-col>
				<v-col cols="8" sm="6">
					<p class="my-heading result-heading mb-0">Results for "{{ this.$store.state.listobject.search }}"</p>
				</v-col>
				<v-col cols="4" class="header-link d-sm-flex d-none justify-end align-center pb-7">
					<span v-bind:class="{ active: filter == 'all' }" v-on:click="filter = 'all'">All</span>
					<span v-bind:class="{ active: filter == 'list' }" v-on:click="filter = 'list'">List</span>
					<span v-bind:class="{ active: filter == 'delivery' }" v-on:click="filter = 'delivery'">Delivery</span>
				</v-col>
			</v-row>

			<div v-if="filter === 'all' || filter === 'list'" class="pb-8">
				<v-row no-gutters>
					<p class="my-heading mt-5 mt-sm-0">Lists</p>
				</v-row>
				<div v-if="no_list === true">
					<div class="d-flex align-center justify-center" style="height: 25vh">
						<p class="no-data">No results for lists</p>
					</div>
				</div>
				<div v-if="filterList().length > 0">
					<transition-group name="slide-in" :style="{ '--total': filterList().length }">
						<v-row
							v-for="(data, index) in filterList()"
							v-bind:key="'list' + index"
							no-gutters
							:style="{ '--i': index }"
							class="list-box mb-3 d-sm-flex d-block add-remove-item"
						>
							<div class="list-info d-flex">
								<div v-if="!data.bid.status" class="no-bid-point"></div>
								<div class="list-icon d-flex align-center justify-center">
									<img v-if="data.list.category_id === 1" :src="$store.state.icons.icons['Lumber']" alt />
									<img v-if="data.list.category_id === 2" :src="$store.state.icons.icons['Electric']" alt />
									<img v-if="data.list.category_id === 3" :src="$store.state.icons.icons['Plumbing']" alt />
									<img v-if="data.list.category_id === 4" :src="$store.state.icons.icons['Masonry']" alt />
									<img v-if="data.list.category_id === 5" :src="$store.state.icons.icons['ToolBoxes']" alt />
									<img v-if="data.list.category_id === 6" :src="$store.state.icons.icons['Landscape']" alt />
								</div>
								<div class="list-detail d-flex align-center pl-8 pr-8">
									<div style="width: 100%">
										<p class="heading-mf15">{{ data.list.list_name }}</p>
										<div class="d-flex">
											<p class="heading-rf13">List ref: {{ data.list.list_id }}</p>

											<p v-if="data.list.active_bids > 0" class="heading-rf13 d-flex ml-auto">Quotes: {{ data.list.active_bids }}</p>
										</div>
									</div>
								</div>
							</div>

							<div class="list-address-info d-flex align-center pl-8 pr-8">
								<div class="pt-3 pb-3">
									<p class="heading-mf15">{{ firstAddress(data.list.address) }}</p>
									<p class="heading-rf13">{{ lastAddress(data.list.address) }}</p>
								</div>
							</div>

							<div class="list-bid-info d-flex align-center justify-space-between pl-8 pr-8">
								<div>
									<div v-if="!data.bid.status && data.list.status === 'A'">
										<app-list-hours v-model="data.list.idle_time"></app-list-hours>
									</div>
									<div v-else>
										<p class="heading-mf15">Your Quote</p>
										<p class="heading-rf13-blue">{{ $options.filters.currency(data.bid.total_amount) }}</p>
									</div>
								</div>
								<div v-if="getUserChatsRoomIds.includes(data.listDistCompanyId)" style="position: relative" @click="displayChatScreen(data)">
									<i class="mdi mdi-message-outline chat-icon"></i>
									<chatMessageCount :chatOpen="show_user_chat" :id="data.listDistCompanyId" />
								</div>
							</div>

							<div class="status-info d-sm-flex d-block align-center pl-8 pr-8">
								<div class="ml-auto">
									<div v-if="data.bid.status === 'A'" class="text-tag-btn text-tag-green d-flex align-center justify-center mr-2">
										Accepted
									</div>
									<div v-else-if="data.bid.status === 'LB'" class="text-tag-btn text-tag-red d-flex align-center justify-center mr-2">
										Lost-Quote
									</div>
									<div v-else-if="data.list.status === 'E'" class="text-tag-btn text-tag-blue d-flex align-center justify-center mr-2">
										List Expired
									</div>
									<div
										v-else-if="data.bid.status != 'A' && data.bid.status != 'R'"
										class="text-tag-btn text-tag-blue d-flex align-center justify-center mr-2"
									>
										<p class="ma-0" v-if="!data.bid.status">Awaiting your quote</p>
										<p class="ma-0" v-else-if="data.bid.status === 'E'">Quote Expired</p>
										<p class="ma-0" v-else-if="data.bid.tag_status">{{ data.bid.tag_status }}</p>
										<p class="ma-0" v-else-if="data.bid.status === 'AC'">Submited</p>
									</div>
									<div v-else-if="data.bid.status === 'R'" class="text-tag-btn text-tag-red d-flex align-center justify-center mr-2">
										Rejected
									</div>
								</div>
								<div class="my-btn-disabled">
									<v-btn
										v-if="!data.bid.status"
										class="my-p-btn my_elevation"
										id="my_elevation"
										depressed
										@click.prevent="bid(data.list.list_id)"
										:disabled="data.list.status === 'E' || data.list.status === 'BA'"
										>Quote</v-btn
									>

									<v-btn
										v-else-if="data.bid.status === 'A'"
										class="my-p-btn my_elevation"
										id="my_elevation"
										depressed
										@click.prevent="bidDelivery(data.bid.bid_id)"
										>Delivery</v-btn
									>

									<v-btn
										v-else-if="data.bid.status === 'AC'"
										class="my-p-btn my_elevation"
										id="my_elevation"
										depressed
										@click.prevent="checkBid(data.bid.bid_id)"
										:disabled="data.list.status === 'E' || data.list.status === 'BA'"
										>Check Quote</v-btn
									>

									<v-btn v-else-if="data.bid.status === 'LB'" class="my-p-btn my_elevation" id="my_elevation" depressed disabled
										>Lost-Quote</v-btn
									>

									<v-btn
										v-else-if="data.bid.status === 'R' || data.bid.status === 'E'"
										class="my-p-btn my_elevation"
										id="my_elevation"
										depressed
										@click.prevent="reBid(data.bid.bid_id)"
										:disabled="data.list.status === 'E' || data.list.status === 'BA'"
										>Re-Quote</v-btn
									>
								</div>
							</div>
						</v-row>
					</transition-group>
				</div>
			</div>

			<div v-if="filter === 'all' || filter === 'delivery'">
				<v-row no-gutters>
					<p class="my-heading">Deliveries</p>
				</v-row>
				<div v-if="no_deliverys === true">
					<div class="d-flex align-center justify-center" style="height: 25vh">
						<p class="no-data">No results for deliveries</p>
					</div>
				</div>
				<div v-if="filterDelivery().length > 0">
					<app-delivery-list-view :value="filterDelivery()"></app-delivery-list-view>
				</div>
			</div>
		</v-col>

		<div style="width: 100%">
			<v-menu top auto offset-x min-width="200px" :close-on-click="closeOnClick">
				<template v-slot:activator="{ on, attrs }">
					<div icon class="d-sm-none text-end filter-icon mb-5">
						<i v-if="!filter_exit" @click="filter_exit = !filter_exit" class="mdi mdi-filter-outline icon-circle" v-bind="attrs" v-on="on" />
						<i v-else @click="filter_exit = !filter_exit" class="mdi mdi-close icon-circle" v-bind="attrs" v-on="on" />
					</div>
				</template>

				<v-list width="200px">
					<v-list-item>
						<v-list-item-title>
							<span v-bind:class="{ active: filter == 'all' }" v-on:click=";(filter = 'all'), (filter_exit = !filter_exit)">All</span>
						</v-list-item-title>
					</v-list-item>
					<v-list-item>
						<v-list-item-title>
							<span v-bind:class="{ active: filter == 'list' }" v-on:click=";(filter = 'list'), (filter_exit = !filter_exit)">List</span>
						</v-list-item-title>
					</v-list-item>
					<v-list-item>
						<v-list-item-title>
							<span v-bind:class="{ active: filter == 'delivery' }" v-on:click=";(filter = 'delivery'), (filter_exit = !filter_exit)"
								>Delivery</span
							>
						</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</div>

		<app-bid ref="bid"></app-bid>
		<app-re-bid ref="rebid"></app-re-bid>
		<app-check-bid ref="checkbid"></app-check-bid>
		<app-bid-delivery ref="deliverybid"></app-bid-delivery>
		<chat-message :is_show="show_user_chat" @openUserChat="openUserChat()" @displayChangeBid="displayChangeBid" ref="chatMessage" />
		<change-bid ref="changebid" />
	</v-row>
</template>

<script>
const Bid = () => import('../bidcomponent/Bid')
const ReBid = () => import('../bidcomponent/ReBid')
const CheckBid = () => import('../bidcomponent/CheckBid')
const BidDelivery = () => import('../bidcomponent/BidDelivery')
const DeliveryListView = () => import('../../mycomponents/DeliveryList')
const ChatMessage = () => import('../../mycomponents/ChatMessage.vue')
const changeBid = () => import('../bidcomponent/ChangeBid.vue')
const chatMessageCount = () => import('../../mycomponents/chatMessageCount.vue')

import firebase from 'firebase/app'
import { FIREBASE_COLLECTION_NAME_PREFIX } from '../../config/variable'

export default {
	components: {
		AppBid: Bid,
		AppReBid: ReBid,
		AppCheckBid: CheckBid,
		AppBidDelivery: BidDelivery,
		AppDeliveryListView: DeliveryListView,
		ChatMessage: ChatMessage,
		changeBid: changeBid,
		chatMessageCount: chatMessageCount,
	},

	data() {
		return {
			filter: 'all',
			page: 1,
			no_list: false,
			no_deliverys: false,
			itemsPerPage: 25,
			new_list: true,
			no_data: false,
			list_data: [],
			filter_exit: false,
			closeOnClick: false,
			listDistCompanyId: [],
			show_user_chat: false,
		}
	},

	async mounted() {
		let userCollection = await firebase
			.firestore()
			.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'messages')
			.where('is_active_chat', '==', true)
		userCollection.onSnapshot((doc) => {
			this.listDistCompanyId = []
			doc.forEach((doc) => {
				this.listDistCompanyId.push(doc.id)
			})
		})
	},

	computed: {
		getUserChatsRoomIds() {
			return this.listDistCompanyId
		},
	},

	methods: {
		firstAddress(address) {
			if (address) {
				let first_address = address.split(',')
				return first_address[0]
			}
		},
		back() {
			this.$store.state.listobject.search = ''
			this.$router.back()
		},
		lastAddress(address) {
			if (address) {
				let last_address = address.split(',')
				let full_address = last_address[1] != undefined ? last_address[1] : ''
				full_address = last_address[2] != undefined ? full_address + ', ' + last_address[2] : full_address
				full_address = last_address[3] != undefined ? full_address + ', ' + last_address[3] : full_address
				return full_address
			}
		},

		filterList() {
			let ob = this.$store.state.listobject.list_object.filter(
				(item) => item.list.list_name.toLowerCase().indexOf(this.$store.state.listobject.search.toLowerCase()) > -1
			)

			this.no_list = ob.length > 0 ? false : true
			return ob
		},

		filterDelivery() {
			let ob = this.$store.state.deliveryobject.delivery_object.filter(
				(item) => item.list_name.toLowerCase().indexOf(this.$store.state.listobject.search.toLowerCase()) > -1
			)

			this.no_deliverys = ob.length > 0 ? false : true
			return ob
		},

		getList(type, id) {
			let data = {}
			if (type === 'list') {
				data = this.$store.state.listobject.list_object.filter((list) => {
					return list.list.list_id === id
				})
			} else if (type === 'bid') {
				data = this.$store.state.listobject.list_object.filter((bid) => {
					return bid.bid.bid_id === id
				})
			}

			return {
				list: data[0]['list'],
				bid: data[0]['bid'],
				contractor: data[0]['contractor'],
				notification: {},
			}
		},

		bid(list_id) {
			let data = this.getList('list', list_id)
			this.$store.commit('listReset')
			this.$store.commit('listData', JSON.parse(JSON.stringify(data)))
			this.$refs.bid.bidSubmit(list_id)
		},

		reBid(bid_id) {
			let data = this.getList('bid', bid_id)
			this.$store.commit('listReset')
			this.$store.commit('listData', JSON.parse(JSON.stringify(data)))
			this.$refs.rebid.reBidSubmit(bid_id)
		},

		checkBid(bid_id) {
			let data = this.getList('bid', bid_id)
			this.$store.commit('listReset')
			this.$store.commit('listData', JSON.parse(JSON.stringify(data)))
			this.$refs.checkbid.checkBidSubmit(bid_id)
		},

		bidDelivery(bid_id) {
			this.$refs.deliverybid.deliverySubmit(bid_id)
		},

		async displayChatScreen(data) {
			this.$refs.chatMessage.resetUserMessages()
			this.show_user_chat = true

			this.$store.state.chathaggle.collection_id = data.listDistCompanyId
			this.$store.state.chathaggle.active_bid_id = data.bid.bid_id

			this.$store.state.chathaggle.contractor_id = data.list.contractor_id

			this.$refs.chatMessage.getUserMessages(data.listDistCompanyId)

			this.$refs.changebid.getLastBidObjId(data.listDistCompanyId)

			let user = await firebase
				.firestore()
				.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'users')
				.doc(`${data.list.contractor_id}C`)
			user.onSnapshot((doc) => {
				this.$store.dispatch('setChatUser', doc.data())
			})
			this.setZeroCount()
		},

		async setZeroCount() {
			let msgCountSnapshot = await firebase
				.firestore()
				.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'messages')
				.doc(this.$store.state.chathaggle.collection_id)
				.get()
			let count = msgCountSnapshot.data()
			if (count.new_message_count) {
				await firebase
					.firestore()
					.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'messages')
					.doc(this.$store.state.chathaggle.collection_id)
					.update({ new_message_count: 0 })
			}
		},

		async openUserChat() {
			this.setZeroCount()
			setTimeout(() => {
				this.show_user_chat = false
			}, 100)
		},

		displayChangeBid(data) {
			this.$refs.changebid.checkBidSubmit(data)
		},
	},
}
</script>

<style scoped>
.chat-icon {
	padding: 10px 10px 7px;
	color: #0074ff;
	font-size: 25px;
	border: 1px solid #e9ecf4;
	border-radius: 4px;
	width: 40px;
	height: 40px;
	cursor: pointer;
}
</style>
